<template>
	<div>
		<el-result icon="success" v-if="user" :title="user | format_title">
			<template slot="icon">
				<img style="width: 400px" :src="user.avatar" alt="">
			</template>
			<template slot="subTitle">
				<p>是否同意使用：</p>
				<p v-if="user.mobile"><i class="el-icon-mobile-phone"></i> {{user.mobile}}</p>
				<p v-if="user.email"><i class="el-icon-message"></i> {{user.email}}</p>
			</template>
			<template slot="extra">
				<el-button type="primary" size="medium" @click="submitForm">注册/绑定</el-button>
			</template>
		</el-result>
		<el-result icon="info" v-else title="获取授权" subTitle="正在获取用户信息！"></el-result>
	</div>
</template>

<script>
	import { mapMutations } from 'vuex';
	
	export default {
		filters: {
			format_title ({name, gender}) {
				var sex = '少年';
				if (gender == 2) {
					sex = '小姐姐';
				}
				if (gender == 1) {
					sex = '小哥哥';
				}
				return 'Hi！'+name+'，'+sex+'。欢迎使用本系统';
			}
		},
		computed: {
		},
		methods: {
			...mapMutations(['login']),
			async submitForm () {
				const res = await this.$http.post(this.$api.URI_LOGIN, this.form);
				const { redirect_uri } = this.$route.query;
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: '登录成功！正在进入系统...',
					onClose: () => {
						this.login(result);
						if (!redirect_uri) return this.$router.push({path: '/'});
						window.location.href = decodeURIComponent(redirect_uri);
					}
				});
			}
		},
		async created () {
			const { appid, agentid, code } = this.$route.query;
			if (!appid) return this.$message.error("暂无appid");
			if (!agentid) return this.$message.error("暂无agentid");
			if (!code) return this.$message.error("暂无code");
			// code 第一次获取用户信息，二次提交说明用户同意注册账户
			const res = await this.$http.post(this.$api.URI_LOGIN, {action: 'wochat', code, appid, agentid});
			const { code: c, msg, result } = res.data;
			if (c != 0) return this.$message.error(msg);
			this.user = result;
			this.form = { action: 'wochat', appid, agentid, ticket: code };
		},
		data() {
			return {
				user: null,
				form: null,
			}
		}
	}
</script>